import React, { useState } from "react";
import {
  CodeBracketIcon,
  SwatchIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import { Link } from "./../util/router";
import FeatureIcon from "./FeatureIcon";
import { useAuth } from "./../util/auth";

function Navbar(props) {
  const auth = useAuth();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const classes = {
    navLink:
      "font-semibold inline-flex items-center space-x-1 h-8 px-4 group-hover:text-blue-500 py-6",
    navLinkIcon:
      "opacity-50 transform transition duration-200 ease-out group-hover:rotate-180 inline-block w-4 h-4",
    dropdown: {
      base: "absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in opacity-0 group-hover:opacity-100",
      left: "right-0",
      center: "left-1/2 -translate-x-1/2",
      right: "left-0",
      normal: "w-48",
      large: "w-96",
      inner:
        "bg-white shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden",
      title:
        "text-xs uppercase font-semibold tracking-wider text-blue-800 mb-5",
      link: "text-gray-600 hover:text-blue-600 font-medium text-sm flex items-center space-x-2",
      icon: "opacity-25 inline-block w-5 h-5",
      feature:
        "p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm",
      featureName: "font-semibold mb-1",
      featureDescription: "opacity-75",
    },
    accountDropdown: {
      base: "absolute right-0 origin-top-right mt-2 w-48 z-10",
      inner:
        "bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded",
      link: "flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700",
      linkActive: "text-gray-700 bg-gray-100",
      linkInactive:
        "text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700",
      icon: "opacity-50 inline-block w-5 h-5",
    },
  };

  return (
    <Section bgColor={props.bgColor}>
      <div className="container py-4">
        <div className="flex justify-between">
          <Link
            to="/"
            className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-blue-600 hover:text-blue-400"
          >
            <img src={"icon.png"} className="w-10 h-10" />

            <span>ClipQuest</span>
          </Link>
        </div>
        <div className={"lg:hidden" + (!mobileNavOpen ? " hidden" : "")}>
          <div className="grid grid-cols-1 sm:grid-cols-3 border rounded-xl my-5">
            <div className="col-span-2 p-3 space-y-6">
              <div className="px-3 pt-3">
                <h4 className={`${classes.dropdown.title}`}>
                  Featured Products
                </h4>
              </div>
              <nav className="flex flex-col space-y-1">
                <Link
                  to="/"
                  className={`hover:text-blue-600 hover:bg-blue-50 ${classes.dropdown.feature}`}
                >
                  <div>
                    <FeatureIcon color="blue">
                      <CodeBracketIcon />
                    </FeatureIcon>
                  </div>
                  <div>
                    <h5 className={`${classes.dropdown.featureName}`}>
                      HTML Editor
                    </h5>
                    <p className={`${classes.dropdown.featureDescription}`}>
                      Write and manipulate your markup directly in your browser
                    </p>
                  </div>
                </Link>
                <Link
                  to="/"
                  className={`hover:text-emerald-600 hover:bg-emerald-50 ${classes.dropdown.feature}`}
                >
                  <div>
                    <FeatureIcon color="emerald">
                      <SwatchIcon />
                    </FeatureIcon>
                  </div>
                  <div>
                    <h5 className={`${classes.dropdown.featureName}`}>
                      CSS Editor
                    </h5>
                    <p className={`${classes.dropdown.featureDescription}`}>
                      Style your markup code with smart tools supporting Sass
                    </p>
                  </div>
                </Link>
                <Link
                  to="/"
                  className={`hover:text-orange-600 hover:bg-orange-50 ${classes.dropdown.feature}`}
                >
                  <div>
                    <FeatureIcon color="orange">
                      <PuzzlePieceIcon />
                    </FeatureIcon>
                  </div>
                  <div>
                    <h5 className={`${classes.dropdown.featureName}`}>
                      Web Page Builder
                    </h5>
                    <p className={`${classes.dropdown.featureDescription}`}>
                      Explore all available components and build your website
                    </p>
                  </div>
                </Link>
              </nav>
            </div>
            <div className="p-6 space-y-6">
              <h4 className={`${classes.dropdown.title}`}>Learn</h4>
              <nav className="flex flex-col space-y-3">
                <Link to="/" className={`${classes.dropdown.link}`}>
                  Resource Center
                </Link>
                <Link to="/" className={`${classes.dropdown.link}`}>
                  Developer
                </Link>
                <Link to="/" className={`${classes.dropdown.link}`}>
                  User Guides
                </Link>
              </nav>
              <h4 className={`${classes.dropdown.title}`}>More</h4>
              <nav className="flex flex-col space-y-3">
                <Link to="/about" className={`${classes.dropdown.link}`}>
                  About
                </Link>
                <Link to="/pricing" className={`${classes.dropdown.link}`}>
                  Pricing
                </Link>

                {!auth.user && (
                  <Link
                    to="/auth/signin"
                    className={`${classes.dropdown.link}`}
                  >
                    Sign in
                  </Link>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Navbar;

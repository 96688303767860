import React from "react";
import {
  AdjustmentsVerticalIcon,
  ChartPieIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = [
    {
      title: "Clip Compilation",
      description:
        "Create personalized compilations of your favorite gaming moments. Making it easier to showcase your gaming journey.",
      icon: AdjustmentsVerticalIcon,
      iconColor: "orange",
    },
    {
      title: "Find your Highlights",
      description:
        "Automatically pinpoint and highlight the most engaging and exciting moments in your gaming videos. This feature uses audio and visual cues to detect key events, saving you time and effort.",
      icon: ChartPieIcon,
      iconColor: "red",
    },

    {
      title: "Any language",
      description:
        "Seamlessly convert your video content into accurate transcripts, supporting a multitude of languages. Whether you're a global gamer or a content creator catering to a diverse audience.",
      icon: GlobeAmericasIcon,
      iconColor: "emerald",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
          {features.map((feature, index) => (
            <div
              className="group p-5 transition ease-out duration-200 rounded-2xl text-center"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
              >
                <feature.icon />
              </FeatureIcon2>
              <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
              <p className="leading-relaxed text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
